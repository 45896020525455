



































import { Component, Vue, Watch } from 'vue-property-decorator'
import MerchantDetail from '../../components/MerchantDetail/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/MerchantController'
import { MerchantDetail as MerchantDetailEntity } from '@/domain/entities/Merchant'
import { EventBusConstants } from '@/app/infrastructures/misc'

interface Detail {
  name: string
  value: string
  class?: string
}

@Component({
  components: {
    MerchantDetail,
    Modal,
    Button,
  },
})
export default class MerchantProfilePage extends Vue {
  account: Detail[] = []
  controller = controller

  created(): void {
    const val = controller.merchantDetail
    this.getAccount(val)
  }

  get modal(): string {
    return this.$attrs?.modal
  }

  private updateMerchantStatus(): void {
    this.controller.updateMerchantStatus({
      id: Number(controller.merchantDetail.id),
      isActive: controller.merchantDetail.status === 'suspend',
    })
  }

  private getAccount(val: MerchantDetailEntity): void {
    this.account = [
      {
        name: 'Name',
        value: <string>val.accountName,
      },
      {
        name: 'Email',
        value: <string>val.accountEmail,
      },
      {
        name: 'Phone',
        value: <string>val.accountPhone,
      },
      {
        name: 'Status',
        value: <string>val.status,
        class: 'capitalize',
      },
    ]
  }

  get merchantStatus(): boolean {
    return controller.merchantDetail.status === 'active'
  }

  @Watch('controller.merchantDetail')
  onMerchantDetailChanged(merchantDetail: MerchantDetailEntity): void {
    this.getAccount(merchantDetail)
  }

  private hideModal(): void {
    this.$emit('showModal', '')
  }

  @Watch('controller.statusUpdateMerchantStatus', { deep: true })
  onStatusUpdateMerchantStatusChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.UPDATE_MERCHANT_STATUS_SUCCESS) {
      this.hideModal()
      controller.getMerchantDetail(String(controller.dataUpdateMerchantStatus))
    }
    controller.setStatusUpdateMerchantStatus('');
    controller.setDataUpdateMerchantStatus(NaN);
  }

  beforeDestroy(): void {
    controller.setStatusUpdateMerchantStatus('');
    controller.setDataUpdateMerchantStatus(NaN);
  }
}
